<template>
	<w-flex v-if="selectedFolder" pa-3 shrink>
		<v-text-field
			v-if="selectedFolder.type === 'string'"
			ref="nameInput"
			v-model.trim="model[selectedFolder.flag]"
			autofocus
			:label="selectedFolder.title"
			:rules="[rules.validName]"
			maxlength="191"
			@keypress.enter="save()"
		/>
		<YearPicker v-if="selectedFolder.type == 'year'" v-model.number="model.year" :label="selectedFolder.title" />
		<SemesterPicker v-if="selectedFolder.type == 'semester'" v-model.number="model.semester" :label="selectedFolder.title" />
		<QuarterPicker v-if="selectedFolder.type == 'quarter'" v-model.number="model.quarter" :label="selectedFolder.title" />
		<MonthPicker v-if="selectedFolder.type == 'month'" v-model.number="model.month" :label="selectedFolder.title" />
		<WeekPicker v-if="selectedFolder.type == 'week'" v-model.number="model.week" :label="selectedFolder.title" />
		<DatePicker v-if="selectedFolder.type == 'day'" v-model="model.day" :label="selectedFolder.title" />
		<w-text-info v-if="selectedFolder.type == 'boolean'" :text="$t(`documents.folder_creation.${selectedFolder.flag}.info`)" />
	</w-flex>
</template>
<script>
export default {
	name: 'ReplicableFolderCreator',
	components: {
		DatePicker: () => ({
			component: import('@/components/Commons/Pickers/DatePicker')
		}),
		MonthPicker: () => ({
			component: import('@/components/Commons/Pickers/MonthPicker')
		}),
		QuarterPicker: () => ({
			component: import('@/components/Commons/Pickers/QuarterPicker')
		}),
		SemesterPicker: () => ({
			component: import('@/components/Commons/Pickers/SemesterPicker')
		}),
		WeekPicker: () => ({
			component: import('@/components/Commons/Pickers/WeekPicker')
		}),
		YearPicker: () => ({
			component: import('@/components/Commons/Pickers/YearPicker')
		})
	},
	props: {
		rules: {
			type: Object,
			required: true
		},
		selectedFolder: {
			type: Object,
			required: true
		},
		value: {
			type: [Array, Object],
			required: true
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		save: function () {
			this.$emit('save', this.model)
		}
	}
}
</script>
